<template>
  <v-app-bar
    app
    absolute
    dark
    prominent
    :height="appBarHeight"
    src="https://szmoon.github.io/tien-wei-chang-images/images/B09-N01/full/2842,/0/default.jpg"
    class="header"
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to bottom, rgba(255, 255, 255, 0.2861519607843137), rgba(2, 0, 36, 0.5)"
      ></v-img>
    </template>

    <div class="header-title"><h1>張天威 | Tien-Wei Chang</h1></div>

    <template v-slot:extension>
      <v-tabs>
        <v-tab :to="{ name: 'home' }">Main</v-tab>
        <v-tab :to="{ name: 'collection' }">Collection</v-tab>
        <v-tab :to="{ name: 'life' }">Life</v-tab>
      </v-tabs>
    </template>
    <!-- -->
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {};
  },
  computed: {
    appBarHeight() {
      if (this.$vuetify.breakpoint.xsOnly) return 150;
      if (this.$vuetify.breakpoint.smOnly) return 220;
      return 300;
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
.header {
  .v-toolbar__content {
    padding: 0;
  }
  .header-title {
    padding: 16px 26px 16px 58px;
    background-color: $maroon--bg-transparent;
    h1 {
      font-size: 32px;
      font-weight: 500;
    }

    @media #{map-get(
        $display-breakpoints,
        "xs-only"
      )} {
      padding: 16px 0;
      width: 100%;

      display: flex;
      justify-content: center;

      h1 {
        font-size: 24px;
      }
    }
  }
  .v-slide-group__content {
    margin-left: 42px;

    @media #{map-get(
        $display-breakpoints,
        "xs-only"
      )} {
      margin: 0;
      justify-content: center;
    }
  }
  .v-tab {
    color: white !important;
    font-size: 18px;

    @media #{map-get(
        $display-breakpoints,
        "xs-only"
      )} {
      font-size: 13px;
    }
  }
}
</style>
