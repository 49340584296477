import Vue from "vue";
import VueRouter from "vue-router";
import BaseView from "~views/BaseView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: BaseView,
    children: [
      {
        name: "home",
        path: "/",

        component: () => import("~views/Home.vue"),
      },
      {
        name: "collection",
        path: "/collection",
        component: () => import("~views/Collection.vue"),
      },
      {
        name: "life",
        path: "/life",
        component: () => import("~views/Life.vue"),
      },
      {
        name: "painting",
        path: "/painting/:id",
        component: () => import("~views/Painting.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
