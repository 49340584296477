<template>
  <v-footer app absolute color="#fbeff3">
    <v-col class="text-center" cols="12">
      {{ new Date().getFullYear() }} —
      <strong>The family of Tien-Wei Chang</strong>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";
.v-footer {
  padding: 40px 58px 80px 58px !important;

  @media #{map-get(
        $display-breakpoints,
        "xs-only"
      )} {
    padding: 20px 12px 40px 12px !important;
  }
}
</style>
