<template>
  <v-app>
    <Header />
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="layout-default__router-view-container">
        <!-- If using vue-router -->
        <router-view />
      </v-container>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Footer from "~components/Footer";
import Header from "~components/Header";
export default {
  name: "LayoutDefault",
  components: { Footer, Header },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.layout-default__router-view-container {
  padding-top: 30px;
  padding-bottom: 140px;
  @media #{map-get($display-breakpoints, "xs-only")} {
    padding-top: 12px;
  }
}
</style>
